import { Icon, solidIcons } from '@/common/components/icon'

import React from 'react'
import classesCommon from './../SccCommon.module.scss'

export const SccLi = (props: { children: React.ReactNode }) => {
  return (
    <li>
      <div className={classesCommon.listIcon}>
        <Icon icon={solidIcons.faArrowCircleRight} />
      </div>

      {props.children}
    </li>
  )
}
