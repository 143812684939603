import { route } from '@/common/lib'
import React from 'react'
import { Helmet } from 'react-helmet'
import { sccUrls } from '.'
import { SccH2, SccH3, SccLi } from './components'
import { SimpleLink } from './components/SimpleLink'
import classesCommon from './SccCommon.module.scss'
import { SccLayout } from './SccLayout'

export const SccFreeTrialPage = () => {
  return (
    <>
      <Helmet>
        <title>Viz</title>
        <meta name='description' content='Razzle coffee application' />
      </Helmet>
      <SccLayout url={sccUrls.freeTrial}>
        <div className={classesCommon.twoColumnLayout}>
          <div>
            <SccFreeTrialMain />
          </div>
          <div>
            <SccFreeTrialRight />
          </div>
        </div>
      </SccLayout>
    </>
  )
}

export const SccFreeTrialMain = () => {
  return (
    <>
      <SccH2>Try Super Crossword Creator for Free</SccH2>
      <SccH3>and make your own crosswords</SccH3>
      <div>
        <br />
        You are just seconds away from making your own Crossword and Word Search
        puzzles.
        <br /> <br />
      </div>
      <div>
        <ul className={classesCommon.list}>
          <SccLi>
            <strong>Unlimited FREE Trial</strong> - The trial version{' '}
            <strong>never expires</strong>. You can use it for{' '}
            <strong>free</strong> forever!
          </SccLi>
          <SccLi>
            Create <strong>Crosswords</strong>
          </SccLi>
          <SccLi>
            <strong>Print</strong> samples in the free trial
          </SccLi>
          <SccLi>No credit card required</SccLi>
          <SccLi>No limitations on saving your crosswords</SccLi>
          <SccLi>Make sure you like it before you purchase</SccLi>
          <ul>
            <br />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <SimpleLink url={sccUrls.launchScc8}>
                Run Super Crossword Creator 8 - No Download Required
              </SimpleLink>

              <div
                style={{
                  backgroundImage: `url(${route.getUrl(
                    '/images/scc/Button-Run-Super-Crossword-Creator-8.png',
                  )})`,
                }}
                className={classesCommon.runButton}
                onClick={() => {
                  route.navTo(sccUrls.launchScc8)
                }}
              ></div>
            </div>

            <br />
          </ul>
          <SccLi>
            Please <SimpleLink url={sccUrls.buyNow}>PURCHASE TODAY</SimpleLink>{' '}
            to unlock all features!
          </SccLi>
        </ul>
        <br />
        <div>
          Have some questions about the free trial? Please read the{' '}
          <SimpleLink url={sccUrls.freeTrialFaq}>Free Trial F.A.Q.</SimpleLink>
        </div>
      </div>
    </>
  )
}

export const Quote = (props: {
  author: React.ReactNode
  children: React.ReactNode
}) => {
  return (
    <div>
      <div className={classesCommon.quote}>"{props.children}"</div>
      <div className={classesCommon.quoteAuthor}>&mdash; {props.author}</div>
    </div>
  )
}

export const SccFreeTrialRight = () => {
  return (
    <>
      <div style={{ width: '200px', textAlign: 'center' }}>
        <SccH3>Make your own crosswords</SccH3>
        <br />
        <Quote author='Vicki'>
          I just wanted to say I love your crossword creator program! Thanks for
          making my life a little easier and more fun!
        </Quote>
        <br />
        <div>Join our 1000's of Satisfied Customers!</div>
        <br />
        <div>
          <SimpleLink url={sccUrls.buyNow}>
            <img src={route.getUrl('/images/scc/misc/Button-Buy-Now.gif')} />
          </SimpleLink>
        </div>
        <br />
        <div>Satisfaction Guaranteed!</div>
        <br />
        <div>
          {/* <SimpleLink url={sccUrls.satisfactionGuaranteed}> */}
          <img
            src={route.getUrl(
              '/images/scc/misc/Satisfaction-Guaranteed-Small.png',
            )}
          />
          {/* </SimpleLink> */}
        </div>
        <Quote author='Tom M.'>
          Super Crossword Creator is a great product. It was really easy for me
          to create my own crossword puzzles.
        </Quote>
      </div>
    </>
  )
}
