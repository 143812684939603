import React from 'react'
import { Helmet } from 'react-helmet'
import { sccUrls } from '.'
import classesCommon from './SccCommon.module.scss'
import { SccLayout } from './SccLayout'

export const SccTutorialPage = () => {
  return (
    <>
      <Helmet>
        <title>Viz</title>
        <meta name='description' content='Razzle coffee application' />
      </Helmet>
      <SccLayout url={sccUrls.tutorial}>
        <div className={classesCommon.twoColumnLayout}>
          <div className={classesCommon.one}>
            <SccTutorialMain />
          </div>
        </div>
      </SccLayout>
    </>
  )
}

export const SccTutorialMain = () => {
  return <>SCC Tutorial</>
}
