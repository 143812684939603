import { route } from '@/common/lib'
import React from 'react'
import { sccUrls } from './'
import { SimpleLink } from './components/SimpleLink'
import { SimpleLinkSeparator } from './components/SimpleLinkSeparator'
import classes from './SccHeader.module.scss'

export const SccHeader = () => {
  return (
    <>
      <div>
        <div style={{ padding: '5px' }}>
          {' '}
          <SimpleLink url={sccUrls.existingUsers}>
            Existing users click here
          </SimpleLink>{' '}
          | <SimpleLink url={sccUrls.login}>Login</SimpleLink> |{' '}
          <SimpleLink url={sccUrls.launchScc8}>
            Launch Super Crossword Creator 8
          </SimpleLink>
        </div>
        <div className={classes.logoRow}>
          <div>
            <img
              src={route.getUrl(
                '/images/scc/logos/Super-Crossword-Creator-Logo-H70.png',
              )}
            />
          </div>
          <div>
            <div style={{ paddingLeft: '20px', lineHeight: '32px' }}>
              <span className={classes.tagLine1}>
                The <strong>Easiest Crossword Creation Software</strong>
              </span>
              <span
                className={classes.tagLine2}
                style={{ paddingLeft: '40px', lineHeight: '16px' }}
              >
                for <strong>Teachers</strong> and Other Crossword Lovers
              </span>
            </div>
            <div className={classes.linkLine}>
              <SimpleLink url={sccUrls.freeTrial}>Try Now for Free</SimpleLink>
              <SimpleLinkSeparator />
              <SimpleLink url={sccUrls.freeTrial}>
                For Mac and Windows
              </SimpleLink>
              <SimpleLinkSeparator />
              <SimpleLink url={sccUrls.buyNow}>Buy Now</SimpleLink>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
