import React from 'react'
import { SimpleLink } from './components/SimpleLink'
import { sccUrls } from '.'
import { route } from '@/common/lib'
import classesCommon from './SccCommon.module.scss'

export const SccFooter = () => {
  return (
    <>
      <div
        style={{
          backgroundColor: '#FFFFFF',
          borderTop: 'solid 1px #E0DCD8',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <div style={{ margin: '1em' }}>
            <SimpleLink url={sccUrls.home}>Home</SimpleLink> |{' '}
            <SimpleLink url={sccUrls.freeTrial}>Free Trial</SimpleLink> |{' '}
            <SimpleLink url={sccUrls.buyNow}>Buy Now</SimpleLink> |{' '}
            {/* <SimpleLink url={sccUrls.tutorial}>Tutorial</SimpleLink>|{' '} */}
            <SimpleLink url={sccUrls.help}>Help</SimpleLink> |{' '}
            <SimpleLink url={sccUrls.contactUs}>Contact Us</SimpleLink>
          </div>
          <div style={{ margin: '1em' }}>
            <SimpleLink url={sccUrls.existingUsers}>
              Existing users click here
            </SimpleLink>
            <br />
            <br />
            <div className={classesCommon.footerScc}>
              SUPER CROSSWORD CREATOR 8
            </div>
            <div>
              <SimpleLink url={sccUrls.eduBakery}>eduBakery.com's</SimpleLink>{' '}
              Super Crossword Creator 8 — Part of Super Word Games Creator 8
            </div>
            <div>
              &copy; 2013-2019 |{' '}
              <SimpleLink url={sccUrls.privacyPolicy}>
                Privacy Policy
              </SimpleLink>
            </div>
          </div>
        </div>
        <div style={{ width: '100px', marginTop: '1em', marginRight: '1em' }}>
          <SimpleLink url={sccUrls.madeInTheUsa}>
            <img
              src={route.getUrl('/images/scc/misc/Made-in-USA-America-100.png')}
            />
          </SimpleLink>
        </div>
      </div>
    </>
  )
}
