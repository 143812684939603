import React from 'react'

export const SimpleLink = (props: {
  url?: string
  children: React.ReactNode
}) => {
  if (!props.url) {
    return <span>[-{props.children}]</span>
  }
  return <a href={props.url}>{props.children}</a>
}
