import React from 'react'
import { Helmet } from 'react-helmet'
import { sccUrls } from '.'
import { SccH2, SccH3, SccLi, SimpleLink } from './components'
import classesCommon from './SccCommon.module.scss'
import { SccLayout } from './SccLayout'
import { l } from '@/common/lib/lodash'
import { route } from '@/common/lib'

export const SccPurchasePage = () => {
  return (
    <>
      <Helmet>
        <title>Viz</title>
        <meta name='description' content='Razzle coffee application' />
      </Helmet>
      <SccLayout url={sccUrls.purchase}>
        <div className={classesCommon.twoColumnLayout}>
          <div className={classesCommon.one}>
            <SccPurchaseMain />
          </div>
        </div>
      </SccLayout>
    </>
  )
}
export const SccPurchaseMain = () => {
  return (
    <>
      <SccH2>Purchase Super Crossword Creator Today</SccH2>
      <SccH3>
        Right now, you can use Super Crossword Creator completely free!
      </SccH3>
      <div>
        While we are rebuilding Super Crossword Creator it is free for everyone
        to use
      </div>
    </>
  )
}
