import React from 'react'
import { Helmet } from 'react-helmet'
import { SccLayout } from './SccLayout'
import { SccBox } from './components/SccBox'
import classesCommon from './SccCommon.module.scss'
import { SccLi, SccH2, SccH3, SccContactEmail } from './components'
import { route } from '@/common/lib'
import { sccUrls } from '.'

export const SccAboutPage = () => {
  return (
    <>
      <Helmet>
        <title>Viz</title>
        <meta name='description' content='Razzle coffee application' />
      </Helmet>
      <SccLayout url={sccUrls.contactUs}>
        <div className={classesCommon.twoColumnLayout}>
          <div className={classesCommon.one}>
            <SccAboutMain />
          </div>
          <div className={classesCommon.two}>
            <SccAboutRight />
          </div>
        </div>
      </SccLayout>
    </>
  )
}

export const SccAboutMain = () => {
  return (
    <div>
      <SccH2>About the Super Crossword Creator Team</SccH2>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <SccContactEmail />
      </div>

      <p>
        We are a small, family owned company dedicated to creating great tools
        to help teachers out.
      </p>
      <p>
        We are constantly improving Super Crossword Creator! Your feedback and
        input are greatly appreciated.
      </p>
      <p>
        We are committed to creating the best crossword making program possible,
        and you are instrumental in helping us do that.
      </p>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <SccBox>
          <div>
            Feel free to send us some snail mail or a postcard <br />
            with your experiences:
          </div>
          <br />
          <div style={{ textAlign: 'left', marginLeft: '5em' }}>
            <SccH3>Our office is:</SccH3>
            <div>
              <strong>SuperCrosswordCreator.com</strong>
            </div>
            <div>
              <strong>Attn: Steve Bushman</strong>
            </div>
            <div>241 Walter St, Suite A</div>
            <div>Madison, WI 53714</div>
            <div>USA</div>
          </div>
        </SccBox>
      </div>
      <p>
        Sorry, we are far too busy to provide phone support. In order for us to
        provide the best service possible for you, please email us at the
        address above. Thanks!
      </p>
      <SccH2>Made in the USA</SccH2>
      <SccH3>SUPER CROSSWORD CREATOR IS 100% MADE IN THE USA</SccH3>
      <div>
        eduBakery.com and www.SuperCrosswordCreator.com are proud to be a
        business in the United States of America.
      </div>
      <div>
        <ul className={classesCommon.list}>
          <SccLi>We are a family owned and operated small business</SccLi>
          <SccLi>I, Steve Bushman, am proud to be an American Citizen</SccLi>
          <SccLi>
            All of our software engineering is done here in the United States,
            we don't outsource
          </SccLi>
          <SccLi>We use American suppliers</SccLi>
          <SccLi>
            We are a small business, so we pay our taxes and give back to our
            local community
          </SccLi>
        </ul>
      </div>
    </div>
  )
}

export const SccAboutRight = () => {
  return (
    <div style={{ width: '350px', marginLeft: '10px' }}>
      <div>
        <SccH2>Mission Statement</SccH2>
        <div>
          <ul className={classesCommon.list}>
            <SccLi>To Empower Teachers</SccLi>
            <SccLi>To Make Learning Fun</SccLi>
            <SccLi>To Make the Highest Quality Software</SccLi>
            <SccLi>To Provide Great Customer Support</SccLi>
            <SccLi>To Fix Problems Quickly</SccLi>
            <SccLi>
              To Make our Customers (and their Customers, Students, Children,
              Grandchildren...) Happy!
            </SccLi>
            <SccLi>We are a family owned and operated small business</SccLi>
          </ul>
        </div>
      </div>
      <div>
        <SccH2>Our Staff</SccH2>
        <br />
        <div>
          <SccH3>The Kiddo</SccH3>
          <div>
            Provides the motivation to keep going and improving! Future owner
            someday, perhaps?
          </div>
          <div>
            <img src={route.getUrl('/images/scc/staff/about-kiddo.png')} />
          </div>
          <br />
          <SccH3>Steve Bushman</SccH3>
          <div>
            Does just about everything around here! Support, engineering, most
            of the web design.
          </div>
          <div>
            <img
              src={route.getUrl(
                '/images/scc/staff/aboutus-steve-greatwall.jpg',
              )}
            />
          </div>
          <br />
          <SccH3>You!</SccH3>
          Super Crossword Creator 8 would not be complete without your feedback
          and support! Please send us an email today.
        </div>
      </div>
      <div>
        <SccContactEmail />
      </div>
    </div>
  )
}
