import React from 'react'
import { Helmet } from 'react-helmet'
import { sccUrls, SccFreeTrialRight } from '.'
import { SccLi, SccH3, SccBox, SccEmailMe, SccH2 } from './components'
import { SimpleLink } from './components/SimpleLink'
import classesCommon from './SccCommon.module.scss'
import { SccLayout } from './SccLayout'
import { route } from '@/common/lib'

export const SccHelpPage = () => {
  return (
    <>
      <Helmet>
        <title>Viz</title>
        <meta name='description' content='Razzle coffee application' />
      </Helmet>
      <SccLayout url={sccUrls.help}>
        <div className={classesCommon.twoColumnLayout}>
          <div className={classesCommon.one}>
            <SccHelpMain />
          </div>
          <div className={classesCommon.two}>
            <SccFreeTrialRight />
          </div>
        </div>
      </SccLayout>
    </>
  )
}

export const FaqQ = (props: { children: React.ReactNode }) => {
  return <div style={{ fontWeight: 'bold' }}>{props.children}</div>
}
export const FaqA = (props: { children: React.ReactNode }) => {
  return <div style={{ marginBottom: '1.5em' }}>{props.children}</div>
}

export const SccHelpMain = () => {
  return (
    <>
      <SccH2>Help and Support for Super Crossword Creator</SccH2>
      Have some questions about the free trial? Please read the{' '}
      <SimpleLink url={sccUrls.freeTrialFaq}>Free Trial F.A.Q.</SimpleLink>
      <SccBox>
        The best way to contact us is by email:
        <br /> Steve Bushman
        <br />
        <SccEmailMe />
      </SccBox>
      <div>
        <h3>Frequently Asked Questions:</h3>
        <FaqQ>Do you have an Apple / Mac version?</FaqQ>
        <FaqA>
          Yes! Super Crossword Creator 8 works with all modern Apple computers
          <SimpleLink url={sccUrls.freeTrial}>Try it here</SimpleLink>
        </FaqA>
        <FaqQ>
          Do you support Windows Vista? Windows XP? Windows 7? Windows 8?
          Windows 10?
        </FaqQ>
        <FaqA>
          Yes! Super Crossword Creator 8 works with all Windows computers
          <SimpleLink url={sccUrls.freeTrial}>Try it here</SimpleLink>
        </FaqA>
        <FaqQ>Why do I have red squiggly lines under some of my words?</FaqQ>
        <FaqA>
          That's the spell checker - right click on the underlined words and
          Super Crossword Creator will suggest possible spellings for you. If
          you are doing foreign language crosswords, you may have to ignore the
          squigglies.
        </FaqA>
        <FaqQ>I have another problem not listed here</FaqQ>
        <FaqA>
          Please send us an email at <SccEmailMe /> so we can resolve your
          issue. We want to make all of our customers happy so please do let us
          know. Also, we will put up the solution this F.A.Q. so you will be
          instrumental in helping others as well!
        </FaqA>
      </div>
    </>
  )
}
