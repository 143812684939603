export { _ } from '@/common/lib/lodash'
export { log, logError } from '@/common/lib/log/log'
import React from 'react'
export { React }

import * as R from 'ramda'
export const r = R

const mapIndexed: any = r.addIndex(r.map)
export const re = {
  mapIndexed,
}

import nanoid from 'nanoid'
import { DateTime } from 'luxon'
export { nanoid, DateTime }

export const assert = (
  assertion: boolean,
  message: string = 'assertion failed!',
) => {
  if (!assertion) {
    throw new Error(message)
  }
}
