import React from 'react'
import { Route, Switch, useLocation } from 'react-router'
import { Page404 } from '../shared/Page404'
import {
  SccAboutPage,
  SccBuyNowPage,
  SccExistingUsersPage,
  SccHelpPage,
  SccPrivacyPolicyPage,
  SccPurchasePage,
  SccTutorialPage,
  sccUrls,
} from './siteScc'
import { SccFreeTrialFaqPage } from './siteScc/SccFreeTrialFaqPage'
import { SccFreeTrialPage } from './siteScc/SccFreeTrialPage'
import { SccHomePage } from './siteScc/SccHomePage'
import { SccMadeInUsaPage } from './siteScc/SccMadeInUsaPage'

const DefaultRoute = () => {
  let location = useLocation()
  return (
    <div>
      Default route! No match for <code>{location.pathname}</code>
    </div>
  )
}

export const SccRoutes = () => {
  return (
    <Switch>
      <Route exact={true} path={sccUrls.home} component={SccHomePage} />
      <Route
        exact={true}
        path={sccUrls.freeTrial}
        component={SccFreeTrialPage}
      />
      <Route exact={true} path={sccUrls.contactUs} component={SccAboutPage} />
      <Route exact={true} path={sccUrls.help} component={SccHelpPage} />
      <Route exact={true} path={sccUrls.buyNow} component={SccBuyNowPage} />

      <Route
        exact={true}
        path={sccUrls.existingUsers}
        component={SccExistingUsersPage}
      />

      <Route
        exact={true}
        path={sccUrls.privacyPolicy}
        component={SccPrivacyPolicyPage}
      />

      <Route
        exact={true}
        path={sccUrls.freeTrialFaq}
        component={SccFreeTrialFaqPage}
      />
      <Route
        exact={true}
        path={sccUrls.madeInTheUsa}
        component={SccMadeInUsaPage}
      />
      <Route exact={true} path={sccUrls.tutorial} component={SccTutorialPage} />
      <Route
        exact={true}
        pathc={sccUrls.purchase}
        component={SccPurchasePage}
      />
      <Route component={DefaultRoute} />
      <Page404 />
    </Switch>
  )
}
