import React from 'react'
import { Helmet } from 'react-helmet'
import { sccUrls } from '.'
import classesCommon from './SccCommon.module.scss'
import { SccLayout } from './SccLayout'

export const SccMadeInUsaPage = () => {
  return (
    <>
      <Helmet>
        <title>Viz</title>
        <meta name='description' content='Razzle coffee application' />
      </Helmet>
      <SccLayout url={sccUrls.madeInTheUsa}>
        <div className={classesCommon.twoColumnLayout}>
          <div className={classesCommon.one}>
            <SccMadeInUsaMain />
          </div>
        </div>
      </SccLayout>
    </>
  )
}

export const SccMadeInUsaMain = () => {
  return <>SCC made in usa</>
}
