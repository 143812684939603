import { History } from 'history'
import { log, logError } from '../imports'
import { googleAnalytics } from '.'

let env: any = {}
if (typeof window !== 'undefined' && (window as any).env) {
  env = (window as any).env
} else if (typeof process !== 'undefined' && process.env) {
  env = process.env
}

let _serverPathname = 'not-set'
export function setServerPathname(pn) {
  _serverPathname = pn
}
export function getCurrentPathname() {
  if (typeof window !== 'undefined') {
    return window.location.pathname
  }
  return _serverPathname
}

export function getEnv(key: string, _default = '') {
  return env[key] || _default
}

export function getUrl(url: string) {
  return getEnv('STATIC_S3_URL', '/public') + url
}

let _history: History<any> | null = null
export function init(history: History<any>) {
  log('history', 'installed')
  _history = history

  if (typeof window !== 'undefined') {
    let h = history as any
    h.listen((location, action) => {
      let url = `${location.pathname}${location.search}${location.hash}`
      log('history', action, url)
      googleAnalytics.pageview(url)
    })
  }
}
export function getHistory() {
  return _history
}

export function navTo(href: string, replace: boolean = false) {
  if (_history) {
    log('history', `navigating to ${href} (at ${window.location.href})`)

    if (window.location.pathname === href || window.location.href === href) {
      log('history', '(already here, no navigation required)')
      return
    }
    if (replace) {
      _history.replace(href)
    } else {
      _history.push(href)
    }
  } else {
    logError('history', 'no history installed, cannnot route!', href)
    // window.location.href = href
  }
}
