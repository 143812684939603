import { Error404 } from '@/common/components'
import React from 'react'

import { Layout } from '..'

export const Page404 = () => {
  return (
    <Layout>
      <Error404 />
    </Layout>
  )
}
