import nanoid from 'nanoid'
import generate from 'nanoid/generate'
import { _ } from '@/common/imports'

// see: https://en.wikipedia.org/wiki/List_of_emoticons
// const alphabet =
//   '☺️🙂😊😀😁😃😄😆😍☹️🙁😠😡😞😟😣😖😢😭😂😨😧😦😱😫😩😮😯😲😗😙😚😘😍😉😜😘😛😝😜🤑🤔😕😟😐😑😳😞😖🤐😶😇👼😈😎😪😏😒😵😕🤕🤒😷🤢🤨🌹💔❤️😣😖👶😅😳😓😥😴😉😜😕😶😵🙄😀😅😆😃😄🙌😭😢😒😩😑😞😔😫😩😪😺😸😹😻😼😽🙀😿😾🐱🙍😔😕😵🤦😃😄☺️😁😀😍👋👽👾😍😀🙌💃😍😂'

// const a2 = '😘😚😙😗😬😠😡😨😱😮😲🍵☕️☄️🌟🐠🐟🐡🦈🐬🐳🐋🐙🐍🦇💣'

const alpha =
  '🀄🃏🅰🅱🅾🅿🆎🆑🆒🆓🆔🆕🆖🆗🆘🆙🆚🇦🇧🇨🇩🇪🇫🇬🇭🇮🇯🇰🇱🇲🇳🇴🇵🇶🇷🇸🇹🇺🇻🇼🇽🇾🇿🈁🈂🈚🈯🈲🈳🈴🈵🈶🈷🈸🈹🈺🉐🉑🌀🌁🌂🌃🌄🌅🌆🌇🌈🌉🌊🌋🌌🌍🌎🌏🌐🌑🌒🌓🌔🌕🌖🌗🌘🌙🌚🌛🌜🌝🌞🌟🌠🌡🌤🌥🌦🌧🌨🌩🌪🌫🌬🌭🌮🌯🌰🌱🌲🌳🌴🌵🌶🌷🌸🌹🌺🌻🌼🌽🌾🌿🍀🍁🍂🍃🍄🍅🍆🍇🍈🍉🍊🍋🍌🍍🍎🍏🍐🍑🍒🍓🍔🍕🍖🍗🍘🍙🍚🍛🍜🍝🍞🍟🍠🍡🍢🍣🍤🍥🍦🍧🍨🍩🍪🍫🍬🍭🍮🍯🍰🍱🍲🍳🍴🍵🍶🍷🍸🍹🍺🍻🍼🍽🍾🍿🎀🎁🎂🎃🎄🎅🎆🎇🎈🎉🎊🎋🎌🎍🎎🎏🎐🎑🎒🎓🎖🎗🎙🎚🎛🎞🎟🎠🎡🎢🎣🎤🎥🎦🎧🎨🎩🎪🎫🎬🎭🎮🎯🎰🎱🎲🎳🎴🎵🎶🎷🎸🎹🎺🎻🎼🎽🎾🎿🏀🏁🏂🏃🏄🏅🏆🏇🏈🏉🏊🏋🏌'

//const codes = alphaEmoji

let uniqueAlphabet = _.uniq(alpha)

// console.log(uniqueAlphabet.length, uniqueAlphabet)
if (uniqueAlphabet.length !== 256) {
  throw new Error('encoding alphabet should be 256 characters')
}

export const nid = (num: number = 10) => {
  return nanoid(num)
}

export const fid = (num: number = 8) => {
  return generate(uniqueAlphabet, num)
}
