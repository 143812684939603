import 'react-app-polyfill/ie11'

import App from '@/App'
import { route, googleAnalytics } from '@/common/lib'
import { createBrowserHistory } from 'history'
import React from 'react'
import { hydrate } from 'react-dom'
import { Router } from 'react-router-dom'

import { secretsClient } from './../secrets/secretsClient'

let customHistory: any = null

if (typeof window !== 'undefined') {
  customHistory = route.getHistory()
  if (!customHistory) {
    customHistory = createBrowserHistory()
    route.init(customHistory)
    googleAnalytics.init(secretsClient.googleAnalyticsTrackingId)
  }
}

if (typeof document !== 'undefined') {
  hydrate(
    <Router history={customHistory}>
      <App />
    </Router>,
    document.getElementById('root'),
  )

  if (module.hot) {
    module.hot.accept()
  }
}

export function init() {}
