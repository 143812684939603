import ReactGA from 'react-ga'
import { log, logError } from '../imports'

export function init(googleAnalyticsTrackingId: string) {
  ReactGA.initialize(googleAnalyticsTrackingId)
  log('ga', 'init', googleAnalyticsTrackingId)

  let firstUrl = `${window.location.pathname}${window.location.search}${window.location.hash}`
  log('ga', 'first pageview', firstUrl)
  ReactGA.pageview(firstUrl)
}

export function pageview(url: string) {
  log('ga', 'pageview', url)
  ReactGA.pageview(url)
}
