import React from 'react'

export const Error404 = (props: { children?: React.ReactNode }) => {
  return (
    <div>
      <h2>404 Not Found</h2>
      <div>{props.children}</div>
    </div>
  )
}
