import React from 'react'
import { SccBox } from '.'

export const SccContactEmail = () => {
  return (
    <SccBox>
      <div>The best way to contact us is by email:</div>{' '}
      <div>
        <strong>Steve Bushman</strong>
      </div>
      <div>Steve@SuperCrosswordCreator.com</div>
    </SccBox>
  )
}
