import { Icon, solidIcons } from '@/common/components/icon'
import { route } from '@/common/lib'
import React from 'react'
import { Helmet } from 'react-helmet'
import { sccUrls } from '.'
import { SccBox, SccLightbox, SimpleLink } from './components'
import classes from './SccCommon.module.scss'
import { SccLayout } from './SccLayout'
const H3 = (props: { children: React.ReactNode }) => {
  return (
    <h3
      style={{
        fontSize: '26px',
        lineHeight: '28px',
        paddingBottom: '10px',
        marginBottom: '0px',
        marginTop: '10px',
      }}
    >
      {props.children}
    </h3>
  )
}

const UnorderedList = (props: { children: React.ReactNode }) => {
  return <ul style={{ listStyleType: 'none' }}>{props.children}</ul>
}

const ListBullet = (props: { children: React.ReactNode }) => {
  return (
    <li>
      <Icon
        icon={solidIcons.faArrowCircleRight}
        className={classes.listBullet}
      />{' '}
      {props.children}
    </li>
  )
}

const GreenText = (props: { children: React.ReactNode }) => {
  return <div className={classes.greenText}>{props.children}</div>
}
const YellowButton = (props: { children: React.ReactNode; url: string }) => {
  return (
    <a href={props.url} className={classes.yellowBox}>
      {props.children}
    </a>
  )
}

export const SccHomePage = () => {
  return (
    <>
      <Helmet>
        <title>Viz</title>
        <meta name='description' content='Razzle coffee application' />
      </Helmet>
      <SccLayout url={sccUrls.home}>
        <div>
          <div
            style={{
              fontSize: '26px',
              lineHeight: '28px',
              textAlign: 'center',
              paddingTop: '10px',
            }}
          >
            Are you a teacher or do you like crosswords? Would you like to save
            time?
          </div>
          <div
            style={{
              fontSize: '30px',
              lineHeight: '30px',
              textAlign: 'center',
              paddingTop: '10px',
              paddingBottom: '10px',
            }}
          >
            Make Your Own Crossword Puzzles Quickly and Easily
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <div>
            <H3>Save Time.</H3>
            <div>
              <UnorderedList>
                <ListBullet>
                  In just <b>5 Minutes</b> you can create professional crossword
                  puzzle.
                </ListBullet>
                <ListBullet>
                  Crosswords are <b>Easy to Grade</b>.
                </ListBullet>
              </UnorderedList>
            </div>
            <H3>Have More Fun.</H3>
            <div>
              <UnorderedList>
                <ListBullet>
                  Fun games for <b>Weddings, Birthday Parties, Baby Showers</b>{' '}
                  and more.
                </ListBullet>
                <ListBullet>
                  Fun and <b>Effective Test and Vocabulary Review</b> for
                  students.
                </ListBullet>
                <ListBullet>
                  <b>Customize</b> your crossword exactly how you want it.
                </ListBullet>
              </UnorderedList>
            </div>
            <div style={{ fontSize: '24px', textAlign: 'center' }}>
              <SimpleLink url={sccUrls.freeTrial}>
                Make your own Crossword!
              </SimpleLink>
            </div>
          </div>
          <div>
            <img src={route.getUrl('/images/scc/Teacher_7.png')} />
          </div>
        </div>

        <div style={{ display: 'flex' }}>
          <SccBox>
            <H3>Free Trial</H3>
            <GreenText>NO CREDIT CARD NEEDED</GreenText>
            <div>Try before you buy!</div>
            <YellowButton url={sccUrls.freeTrial}>Try Now</YellowButton>
          </SccBox>
          <SccBox>
            <H3>Make Crossword Puzzles!</H3>
            <GreenText>NO DOWNLOAD REQUIRED</GreenText>
            <div>Easy to use</div>
            <YellowButton url={sccUrls.freeTrial}>
              Make a Crossword
            </YellowButton>
          </SccBox>
          <SccBox>
            <H3>Purchase Today</H3>
            <GreenText>BACK TO SCHOOL SAVINGS</GreenText>
            <div>Print and Share Large Crosswords</div>
            <YellowButton url={sccUrls.buyNow}>Buy Now</YellowButton>
          </SccBox>
        </div>

        <div style={{ display: 'flex' }}>
          <div>
            <H3>Create Beautiful Crosswords</H3>
            <div style={{ display: 'flex' }}>
              <div>
                <SccLightbox src={'Page 1 (Mobile).png'} />
                <div>
                  <SimpleLink
                    url={route.getUrl('/images/scc/BackToSchoolCrossword.pdf')}
                  >
                    Download Back To School Crossword Sample (PDF)
                  </SimpleLink>
                </div>
              </div>
              <div>
                <GreenText>Easy to grade solutions</GreenText>
                <SccLightbox src={'Page 2 (Custom).png'} />
              </div>
            </div>
          </div>

          <div>
            <H3>Not Just Crosswords!</H3>
            <GreenText>MAKE OTHER WORD GAMES TOO</GreenText>
            Make:
            <UnorderedList>
              <ListBullet>Bingo Cards</ListBullet>
              <ListBullet> Word Searches</ListBullet>
              <ListBullet> Flashcards </ListBullet>
              <ListBullet>Word Scrambles</ListBullet>
              <ListBullet> Word Lists</ListBullet>
              <ListBullet> Crossword Puzzles</ListBullet>
            </UnorderedList>
          </div>
        </div>

        <div style={{ display: 'flex' }}>
          <div>
            <H3>Fully Customizable</H3>
            <GreenText>
              CHANGE FONTS, COLORS, HEADERS, WHATEVER YOU'D LIKE!
            </GreenText>
          </div>
        </div>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>
            <GreenText>MAKE YOUR CROSSWORD YOUR OWN</GreenText>
            <SccLightbox src={'Page 1 (Custom).png'} />
          </div>

          <div>
            <GreenText>MAKE OTHER WORD GAMES TOO!</GreenText>
            <SccLightbox src={'Page 2 (Custom) (2).png'} />
            <SccLightbox src={'Page 1 (Custom) (2).png'} />
            <SccLightbox src={'Page 2 (Custom) (2) (1).png'} />
            <SccLightbox src={'Page 1 (Custom) (1).png'} />
            <SccLightbox src={'Page 1 (Custom) (3).png'} />
            <SccLightbox src={'Page 1 (Custom) (4).png'} />
          </div>
        </div>

        <div style={{ display: 'flex' }}>
          <div>
            <H3>It's as easy as 1, 2, 3</H3>
            <GreenText>
              PLEASE WATCH THIS SHORT 3-MINUTE VIDEO ON HOW TO MAKE A CROSSWORD
              PUZZLE:
            </GreenText>
          </div>
        </div>

        <div style={{ display: 'flex' }}>
          <div>
            <H3>Make HUGE Crossword Puzzles!</H3>
            <GreenText>
              SUPER CROSSWORD CREATOR SUPPORTS UP TO 100 WORDS AND CLUES
            </GreenText>
          </div>
        </div>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>
            <SccLightbox src={'Page 1 (Small).png'} />
          </div>

          <div>
            <div style={{ display: 'flex' }}>
              <SccLightbox src={'Page 2 (Custom) (4).png'} />
              <SccLightbox src={'Page 3 (Custom) (4).png'} />
            </div>
            <div style={{ display: 'flex' }}>
              <SccLightbox src={'Page 4 (Custom) (4).png'} />
              <SccLightbox src={'Page 5 (Custom) (4).png'} />
            </div>
          </div>
        </div>
      </SccLayout>
    </>
  )
}
