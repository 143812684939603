import React from 'react'
import { Helmet } from 'react-helmet'
import { sccUrls } from '.'
import classesCommon from './SccCommon.module.scss'
import { SccLayout } from './SccLayout'
import { SccH2, SccH3, SimpleLink } from './components'

export const SccExistingUsersPage = () => {
  return (
    <>
      <Helmet>
        <title>Viz</title>
        <meta name='description' content='Razzle coffee application' />
      </Helmet>
      <SccLayout url={sccUrls.existingUsers}>
        <div className={classesCommon.twoColumnLayout}>
          <div className={classesCommon.one}>
            <SccExistingUsersMain />
          </div>
        </div>
      </SccLayout>
    </>
  )
}

export const SccExistingUsersMain = () => {
  return (
    <>
      <SccH2>Existing Users</SccH2>
      <SccH3>
        THANKS FOR USING SUPER CROSSWORD CREATOR! HERE ARE SOME LINKS TO THE
        LATEST VERSION:
      </SccH3>
      <div>
        <br />
        <SimpleLink url={sccUrls.launchScc8}>
          Launch Super Crossword Creator 8
        </SimpleLink>
        <br />
      </div>
      <div>
        <div>
          <br />
          <br />
          If you haven't purchased yet, please{' '}
          <SimpleLink url={sccUrls.buyNow}>Purchase Today</SimpleLink> to unlock
          all features!
          <br /> <br />
        </div>
      </div>
      <SccH2>Past versions of Super Crossword Creator:</SccH2>
      <SccH3>LOOKING FOR SUPER CROSSWORD CREATOR 5?</SccH3>
      <SimpleLink url='http://www.supercrosswordcreator.com/Free-Trial.html'>
        Here's where you can download Super Crossword Creator 5
      </SimpleLink>
      <br /> <br />
      <SccH3>LOOKING FOR SUPER CROSSWORD CREATOR 4?</SccH3>
      <SimpleLink url='http://www.supercrosswordcreator.com/Download-Super-Crossword-Creator-All-Versions.html'>
        Here's where you can download Super Crossword Creator 4
      </SimpleLink>
      <br /> <br />
    </>
  )
}
