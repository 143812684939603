import { _ } from '@/common/lib/lodash'
import { getQueryStringParam } from '../queryString'
import { namespace } from 'd3'
let loggingVolume = 500
let loggingNamespaces = ['*']

const cleanNamespace = (namespace) => {
  namespace = (namespace || '')
    .toLowerCase()
    .trim()
    .replace(/\s/, '-')
  if (!namespace) {
    throw new Error('logging namespace cannot be nothing')
  }
  return namespace
}
export function setLoggingVolume(vol: number) {
  loggingVolume = vol
  if (typeof localStorage !== 'undefined') {
    localStorage.setItem('logging:volume', '' + vol)
  }
}
export function getLoggingVolume() {
  return loggingVolume
}
export function setLoggingNamespaces(namespaces: string) {
  loggingNamespaces = _.map(
    _.filter(namespaces.split(','), (c) => c.trim()),
    (c) => cleanNamespace(c),
  )
  if (typeof localStorage !== 'undefined') {
    localStorage.setItem('logging:namespaces', _.join(loggingNamespaces, ','))
  }
}
export function getLoggingNamespaces() {
  return _.join(loggingNamespaces, ', ')
}

export const log = (
  namespace: string | string[],
  message: string,
  ...args: any[]
) => {
  logLevel(namespace, 500, message, ...args)
}

export const logVerbose = (
  namespace: string | string[],
  message: string,
  ...args: any[]
) => {
  logLevel(namespace, 900, message, ...args)
}

export const logLevel = (
  namespace: string | string[],
  volume: number,
  message: string,
  ...args: any[]
) => {
  if (typeof console !== 'undefined') {
    if (loggingVolume >= volume) {
      if (areWeLoggingThisNamespace(namespace)) {
        if (args.length === 1 && _.isFunction(args[0])) {
          args = [args[0]()]
        }

        console.log(namespace, volume, '|', message, ...args)
      }
    }
  }
}

export const logError = (
  namespace: string | string[],
  message: string,
  ...args: any[]
) => {
  if (typeof console !== 'undefined') {
    if (areWeLoggingThisNamespace(namespace)) {
      if (console.error) {
        console.error(namespace, '|', message, ...args)
      } else {
        console.log('ERROR', namespace, '|', message, ...args)
      }
    }
  }
}

export const logWarn = (
  namespace: string | string[],
  message: string,
  ...args: any[]
) => {
  if (typeof console !== 'undefined') {
    if (areWeLoggingThisNamespace(namespace)) {
      if (console.warn) {
        console.warn(namespace, '|', message, ...args)
      } else {
        console.log('WARN', namespace, '|', message, ...args)
      }
    }
  }
}

export const createLogger = (namespace: string) => {
  return {
    x: (message: string, ...args: any[]) => {
      log(namespace, message, ...args)
    },
    log: (message: string, ...args: any[]) => {
      log(namespace, message, ...args)
    },
    logVerbose: (message: string, ...args: any[]) => {
      logVerbose(namespace, message, ...args)
    },
    debug: (message: string, ...args: any[]) => {
      logVerbose(namespace, message, ...args)
    },
    warn: (message: string, ...args: any[]) => {
      logWarn(namespace, message, ...args)
    },
    error: (message: string, ...args: any[]) => {
      logError(namespace, message, ...args)
    },
    catastrophicError: (message: string, ...args: any[]) => {
      logError(namespace, 'CATASTROPHIC', message, ...args)
    },
  }
}

const areWeLoggingThisNamespace = (namespace: string | string[]) => {
  if (loggingNamespaces.length === 0) {
    return false
  }
  if (namespace === '*') {
    return true
  }
  if (_.isString(namespace)) {
    namespace = cleanNamespace(namespace)
    return _.some(loggingNamespaces, (c) => c === namespace || c === '*')
  }
  return _.some(namespace, (c) => areWeLoggingThisNamespace(c))
}

// Load settings from local storage or process.env

if (typeof localStorage !== 'undefined') {
  loggingVolume =
    parseInt(localStorage.getItem('logging:volume') || '500') || 500
  loggingNamespaces = (localStorage.getItem('logging:namespaces') || '*').split(
    ',',
  )
} else if (typeof process !== 'undefined' && process.env) {
  loggingVolume = parseInt(process.env.LOGGING_VOLUME || '500') || 500
  loggingNamespaces = (process.env.LOGGING_NAMESPACES || '*').split(',')
}

// Allow changing of settings via query string e.g. ?volume=600&namespace=1,2,3
if (typeof window !== 'undefined') {
  let v = getQueryStringParam('volume')
  if (v) {
    setLoggingVolume(parseInt(v) || 500)
  }
  let n = getQueryStringParam('namespaces')
  if (n != null) {
    setLoggingNamespaces(n)
  }
}
if (typeof console !== 'undefined') {
  console.log('logging', loggingVolume, loggingNamespaces)
}
