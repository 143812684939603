import classesPrint from '@/common/css/Print.module.scss'

import React from 'react'

import classes from './Layout.module.scss'

export const Layout = (props: { children: React.ReactNode }) => {
  return (
    <div className={classesPrint.notPrintable}>
      <div className={classes.body}>{props.children}</div>
    </div>
  )
}
