import { Icon, solidIcons } from '@/common/components/icon'

import React from 'react'
import classesCommon from './../SccCommon.module.scss'
import { route } from '@/common/lib'

export const SccLightbox = (props: {
  src: string
  // children: React.ReactNode
}) => {
  return (
    <div className={classesCommon.sccLightbox}>
      <div className={classesCommon.sccLightboxInner}>
        <img src={route.getUrl('/images/scc/' + props.src)} />
      </div>
    </div>
  )
}
