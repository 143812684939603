export const eduBakery = 'https://edubakery.com'
const base = '/Super-Crossword-Creator-8' //'/words/scc'
export const home = '/' // base + '/'
export const freeTrial = base + '/Free-Trial'
export const buyNow = base + '/Buy-Now'
export const purchase = base + '/Purchase'
export const existingUsers = base + '/Existing-Users'
export const tutorial = base + '/Tutorial'
export const help = base + '/Help'
export const contactUs = base + '/Contact-Us'
export const privacyPolicy = '/Legal/Privacy-Policy'

export const freeTrialFaq = base + '/Free-Trial-Frequently-Asked-Questions'

export const madeInTheUsa = '/Legal/Made-in-the-USA'
export const login = 'https://edubakery.com/Accounts/Login'
export const launchScc8 =
  'https://edubakery.com/Apps/Super-Word-Games-Creator-8/Super-Crossword-Creator-8'
export const satisfactionGuaranteed = ''
