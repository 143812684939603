import React from 'react'
import { Helmet } from 'react-helmet'
import { sccUrls } from '.'
import { SccH2, SccH3, SccLi, SimpleLink } from './components'
import classesCommon from './SccCommon.module.scss'
import { SccLayout } from './SccLayout'
import { l } from '@/common/lib/lodash'
import { route } from '@/common/lib'

interface ISccPurchaseInfo {
  product: string
  description: string
  lifetimeCostUnsale?: number
  lifetimeCost: number
  yearCostUnsale?: number
  yearCost: number
}

const purchaseInfos: { [key: string]: ISccPurchaseInfo } = {
  wordGamesDeluxe: {
    product: 'Super Word Games Creator 8 DELUXE',
    description: 'Make Crossword Puzzles',
    lifetimeCostUnsale: 99.95,
    lifetimeCost: 59.95,
    yearCostUnsale: 89.95,
    yearCost: 39.95,
  },
  crosswordAndWordsearch: {
    product: 'Super Crossword Creator and Super Word Search Maker 8 COMBO',
    description: 'Make Crossword Puzzles',
    lifetimeCostUnsale: 99.95,
    lifetimeCost: 59.95,
    yearCostUnsale: 59.9,
    yearCost: 39.95,
  },
  crossword: {
    product: 'Super Crossword Creator 8',
    description: 'Make Crossword Puzzles',
    lifetimeCostUnsale: 59.95,
    lifetimeCost: 49.95,
    yearCost: 29.95,
  },
  bingo: {
    product: 'Super Bingo Cards Maker 8',
    description: 'Make Bingo Cards',
    lifetimeCostUnsale: 59.95,
    lifetimeCost: 39.95,
    yearCost: 29.95,
  },
  wordSearch: {
    product: 'Super Word Search Maker 8',
    description: 'Make Word Searches',
    lifetimeCostUnsale: 59.95,
    lifetimeCost: 39.95,
    yearCost: 29.95,
  },
  flashcards: {
    product: 'Super Flashcards Creator 8',
    description: 'Make Flashcards',
    lifetimeCostUnsale: 39.95,
    lifetimeCost: 29.95,
    yearCost: 29.95,
  },
  wordScramble: {
    product: 'Super Word Scramble Maker 8',
    description: 'Make Word Scrambles',
    lifetimeCostUnsale: 39.95,
    lifetimeCost: 29.95,
    yearCost: 29.95,
  },
  wordList: {
    product: 'Super Word List Creator 8',
    description: 'Make Word Lists',
    lifetimeCostUnsale: 39.95,
    lifetimeCost: 9.95,
    yearCost: 9.95,
  },
}

const purchaseInfosBasic: ISccPurchaseInfo[] = [
  purchaseInfos.crosswordAndWordsearch,
  purchaseInfos.crossword,
  purchaseInfos.wordSearch,
  purchaseInfos.bingo,
  purchaseInfos.flashcards,
  purchaseInfos.wordScramble,
  purchaseInfos.wordList,
]

export const SccBuyNowPage = () => {
  return (
    <>
      <Helmet>
        <title>Viz</title>
        <meta name='description' content='Razzle coffee application' />
      </Helmet>
      <SccLayout url={sccUrls.buyNow}>
        <div className={classesCommon.twoColumnLayout}>
          <div className={classesCommon.one}>
            <SccBuyNowMain />
          </div>
        </div>
      </SccLayout>
    </>
  )
}

export const SccBuyBox = (props: {
  title: string
  left: React.ReactNode
  buy1: React.ReactNode
  buy2: React.ReactNode
  small?: boolean
}) => {
  return (
    <div
      style={{ cursor: 'pointer' }}
      onClick={() => {
        route.navTo(sccUrls.purchase)
      }}
    >
      <table
        style={{
          border: 'solid 1px black',
          width: props.small ? '650px' : '750px',
        }}
        cellPadding={0}
        cellSpacing={0}
      >
        <thead>
          <tr>
            <th
              colSpan={3}
              style={{
                backgroundColor: '#FDD44A',
                borderBottom: 'solid 1px black',
                padding: '3px',
              }}
            >
              {props.title}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              style={{
                textAlign: 'center',
                backgroundColor: '#FFF2B9',
                borderRight: 'solid 1px black',
                padding: '3px',
              }}
            >
              {props.left}
            </td>
            <td
              style={{
                width: '120px',
                textAlign: 'center',
                backgroundColor: '#E0FFFA',
                borderRight: 'solid 1px black',
                padding: '3px',
              }}
            >
              {props.buy1}
            </td>
            <td
              style={{
                width: '120px',
                textAlign: 'center',
                backgroundColor: '#E0FFFA',
                padding: '3px',
              }}
            >
              {props.buy2}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
export const SccBuyBoxPurchaseInfo = (props: {
  purchaseInfo: ISccPurchaseInfo
}) => {
  return <div></div>
}

export const SccBuy = (props: {
  title: React.ReactNode
  cost: number
  costUnsale?: number
}) => {
  return (
    <div>
      <div>{props.title}</div>
      <div>
        <img src={route.getUrl('/images/scc/Button-Buy-Now.gif')} />
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {props.costUnsale && (
          <div style={{ textDecoration: 'line-through' }}>
            {'$' + props.costUnsale}&nbsp;
          </div>
        )}
        <a href={sccUrls.purchase}>
          <div>{'$' + props.cost}</div>
        </a>
      </div>
    </div>
  )
}

export const SatisfactionSide = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      <div>
        <img src={route.getUrl('/images/scc/satisfy_sm.png')} />
      </div>
      <div>
        We work hard to make the best products possible.
        <br />
        <br />
        All of our products include a<br />
        <strong>90-Day Money Back Guarantee</strong>
        <br />
        <br />
        If you aren't 100% satisfied,
        <br />
        or if our products don't work for you,
        <br />
        contact us for a quick and easy refund.
      </div>
      <div>
        <img src={route.getUrl('/images/scc/guarantee_sm.gif')} />
      </div>
    </div>
  )
}

export const SccBuyNowMain = () => {
  return (
    <>
      <SccH2>Purchase Super Crossword Creator Today</SccH2>
      <SccH3>
        GET ACCESS TO THE FULL VERSION IMMEDIATELY AFTER YOU PURCHASE
      </SccH3>

      <SccBuyBox
        title={'Make Crosswords and More! – Save Money'}
        left={
          <div>
            Purchase
            <br />
            <strong>Super Word Games Creator 8 DELUXE</strong>
            <br />
            Includes Super Crossword Creator and More!*
            <br />
            Make Crosswords, Bingo Cards, Word Searches, Flashcards, Word
            Scrambles, and more
          </div>
        }
        buy1={
          <SccBuy
            title={
              <div>
                <strong>LIFETIME</strong>
                <br />
                Membership
              </div>
            }
            costUnsale={purchaseInfos.wordGamesDeluxe.lifetimeCostUnsale}
            cost={purchaseInfos.wordGamesDeluxe.lifetimeCost}
          />
        }
        buy2={
          <SccBuy
            title={
              <div>
                <strong>1 Year</strong>
                <br />
                Membership
              </div>
            }
            costUnsale={purchaseInfos.wordGamesDeluxe.yearCostUnsale}
            cost={purchaseInfos.wordGamesDeluxe.yearCost}
          />
        }
      />
      <div>
        *<strong>Includes:</strong> Super Crossword Creator, Super Word Search
        Maker, Super Bingo Cards Maker, Super Flashcards Creator, Super Word
        Scramble Maker, and Super Word List Creator
      </div>
      <div>
        <ul className={classesCommon.list}>
          <SccLi>Instant Access after your purchase</SccLi>
          <SccLi>Free Upgrades!</SccLi>
          <SccLi>
            Make an unlimited number of{' '}
            <strong>
              Crosswords, Bingo Cards, Word Searches, Flashcards, Word Scrambles
            </strong>
            , and more!
          </SccLi>
          <SccLi>
            Works on both Mac and Windows computers with the same purchase
          </SccLi>
          <SccLi>Great customer support</SccLi>
          <SccLi>
            Your order is <strong>completely secure</strong> using
            industry-standard SSL
          </SccLi>

          <SccLi>
            Each purchase is for a single person on all of their computers at
            home, work, school, and on-the-go.
          </SccLi>
          <SccLi>
            <strong>90-Day Money-back Satisfaction Guarantee</strong> - We want
            you to be completely satisfied!
          </SccLi>
        </ul>
      </div>
      <div style={{ display: 'flex' }}>
        <div>
          {l.map(purchaseInfosBasic, (c, cIdx) => (
            <div key={cIdx} style={{ marginBottom: '10px' }}>
              <SccBuyBox
                small
                title={c.product}
                left={<div style={{ fontSize: '24px' }}>{c.description}</div>}
                buy1={
                  <SccBuy
                    title={
                      <div>
                        <strong>LIFETIME</strong>
                        <br />
                        Membership
                      </div>
                    }
                    costUnsale={c.lifetimeCostUnsale}
                    cost={c.lifetimeCost}
                  />
                }
                buy2={
                  <SccBuy
                    title={
                      <div>
                        <strong>1 Year</strong>
                        <br />
                        Membership
                      </div>
                    }
                    costUnsale={c.yearCostUnsale}
                    cost={c.yearCost}
                  />
                }
              />
            </div>
          ))}
        </div>
        <div style={{ marginLeft: '10px' }}>
          <SatisfactionSide />
        </div>
      </div>
    </>
  )
}
