import React from 'react'
import { SccHeader } from './SccHeader'
import { SccFooter } from './SccFooter'
import { SccNav } from './components'

export const SccLayout = (props: {
  children: React.ReactNode
  url: string
}) => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: '#f2f1f0',
          minHeight: '100vh',
        }}
      >
        <div
          style={{
            maxWidth: '950px',
          }}
        >
          <SccHeader />
          <SccNav url={props.url || ''} />
          <div>{props.children}</div>
          <SccFooter />
        </div>
      </div>
    </>
  )
}
