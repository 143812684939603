export function getQueryStringParam(name, _defaultValue = null) {
  if (typeof location !== 'undefined') {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
    var results = regex.exec(location.search)
    return results === null
      ? _defaultValue
      : decodeURIComponent(results[1].replace(/\+/g, ' '))
  }
  return null
}
