import {
  faAngleDown,
  faArrowDown,
  faBook,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronCircleLeft,
  faChevronCircleRight,
  faChevronRight,
  faCoffee,
  faEnvelope,
  faEnvelopeOpenText,
  faList,
  faPlus,
  faPrint,
  faQuestionCircle,
  faSearch,
  faSort,
  faSortDown,
  faSortUp,
  faSyncAlt,
  faTimes,
  faTimesCircle,
  faArrowCircleRight,
  faArrowRight,
} from '@fortawesome/free-solid-svg-icons'

export const solidIcons = {
  faArrowDown,
  faChevronRight,
  faChevronCircleLeft,
  faChevronCircleRight,
  faCoffee,
  faEnvelopeOpenText,
  faPlus,
  faSearch,
  faSort,
  faSortDown,
  faSortUp,
  faSyncAlt,
  faCheck,
  faCheckSquare,
  faBook,
  faQuestionCircle,
  faCheckCircle,
  faTimesCircle,
  faList,
  faPrint,
  faTimes,
  faAngleDown,
  faEnvelope,
  faArrowCircleRight,
  faArrowRight,
}
