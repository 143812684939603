import { createLogger } from '@/common/lib/log'
import React from 'react'
import { Helmet } from 'react-helmet'
import * as Script from 'react-load-script'
import './App.scss'
import './common/css/'
import { log } from './common/imports'
import { route } from './common/lib'
import { logLevel } from './common/lib/log'
import { SccRoutes } from './pages/words'

const logger = createLogger('app-words')

let prefetchedData = {}

if (typeof window !== 'undefined') {
  log('prefetch', 'loading data')
  let { PREFETCHED_DATA } = window as any
  if (PREFETCHED_DATA) {
    prefetchedData = PREFETCHED_DATA // JSON.parse(PREFETCHED_DATA)
  }
  logLevel('prefetch', 900, 'PREFETCHED_DATA', PREFETCHED_DATA, prefetchedData)
}
export const getPrefetchedDataItem = (key) => {
  let item = prefetchedData && prefetchedData[key]
  logLevel('prefetch', 900, 'get', key, item, prefetchedData)
  return item
}

const _handleScriptLoadError = () => {
  logger.error('PDF script loading error!')
  // this.setState({ isPdfKitError: true })
}

const _handleScriptLoad_pdfKit = () => {
  logger.x('PDF script loaded')
  // this.setState({ isPdfKitLoaded: true })
}

export const App = (props: { prefetchedData?: any }) => {
  if (props.prefetchedData) {
    prefetchedData = props.prefetchedData
  }
  return (
    <div>
      <Helmet>
        <title>Raz</title>
        <meta name='description' content='Razzle application' />
      </Helmet>

      <Script
        url={route.getUrl('/assets/pdfkit/0.8.0/pdfkit.js')}
        onLoad={_handleScriptLoad_pdfKit}
        onError={_handleScriptLoadError}
      />

      <SccRoutes />
    </div>
  )
}

export default App
