import React from 'react'
import classes from './SccBox.module.scss'

export const SccBox = (props: { children: React.ReactNode }) => {
  return (
    <div>
      <div className={classes.box}>{props.children}</div>
    </div>
  )
}
