import { Icon, solidIcons } from '@/common/components/icon'

import React from 'react'
import classesCommon from '../SccCommon.module.scss'
import { sccUrls } from '..'
import classes from './SccNav.module.scss'
// export const Tab = (props: {
//   tab: ITab
//   isSelected: boolean
//   onClick: (tab: ITab) => void
// }) => {
//   return (
//     <div
//       className={
//         props.isSelected ? classes.tab + ' ' + classes.selected : classes.tab
//       }
//       onClick={() => {
//         props.onClick(props.tab)
//       }}
//     >
//       {props.tab.label}
//     </div>
//   )
// }

export const SccNavItem = (props: {
  url: string
  selectedUrl: string
  children: React.ReactNode
}) => {
  let isSelected = props.url === props.selectedUrl

  return (
    <div
      className={
        isSelected ? classes.tab + ' ' + classes.selected : classes.tab
      }
      onClick={() => {
        window.location.href = props.url
      }}
    >
      <div>{props.children}</div>
    </div>
  )
}

export const SccNav = (props: { url: string }) => {
  return (
    <div>
      <div className={classes.tabs}>
        <SccNavItem url={sccUrls.home} selectedUrl={props.url}>
          Home
        </SccNavItem>
        <SccNavItem url={sccUrls.freeTrial} selectedUrl={props.url}>
          Free Trial
        </SccNavItem>
        <SccNavItem url={sccUrls.buyNow} selectedUrl={props.url}>
          Buy Now
        </SccNavItem>
        {/* <SccNavItem url={sccUrls.tutorial} selectedUrl={props.url}>
          Tutorial
        </SccNavItem> */}
        <SccNavItem url={sccUrls.help} selectedUrl={props.url}>
          Help
        </SccNavItem>
        <SccNavItem url={sccUrls.contactUs} selectedUrl={props.url}>
          Contact Us
        </SccNavItem>
      </div>
      {/* <div className={classes.arrowUp}>&nbsp;</div> */}
    </div>
  )
}
