import React from 'react'
import { Helmet } from 'react-helmet'
import { sccUrls } from '.'
import { SccLi, SccH3 } from './components'
import { SimpleLink } from './components/SimpleLink'
import classesCommon from './SccCommon.module.scss'
import { SccLayout } from './SccLayout'
import { route } from '@/common/lib'

export const SccPrivacyPolicyPage = () => {
  return (
    <>
      <Helmet>
        <title>Viz</title>
        <meta name='description' content='Razzle coffee application' />
      </Helmet>
      <SccLayout url={sccUrls.tutorial}>
        <div className={classesCommon.twoColumnLayout}>
          <div className={classesCommon.one}>
            <SccPrivacyPolicyMain />
          </div>
        </div>
      </SccLayout>
    </>
  )
}

export const SccPrivacyPolicyMain = () => {
  return (
    <div>
      <h2>www.SuperCrosswordCreator.com and eduBakery.com Privacy Policy:</h2>
      <div style={{ width: '500px' }}>
        <strong>What information do we collect?</strong>
        <br />
        <br />
        We collect information from you when you register on our site, place an
        order, subscribe to our newsletter or submit user content.
        <br />
        <br />
        When ordering or registering on our site, as appropriate, you may be
        asked to enter your: name, e-mail address, mailing address, phone number
        or credit card information. You may, however, visit our site
        anonymously.
        <br />
        <br />
        Like most websites, we use cookies and/or web beacons to enhance your
        experience, gather general visitor information, and track visits to our
        website. Please refer to the 'do we use cookies?' section below for
        information about cookies and how we use them.
        <br />
        <br />
        <strong>What do we use your information for?</strong>
        <br />
        <br />
        Any of the information we collect from you may be used in one of the
        following ways:
        <br />
        <br />
        • To personalize your experience
        <br />
        (your information helps us to better respond to your individual needs)
        <br />
        <br />
        • To improve our website
        <br />
        (we continually strive to improve our website offerings based on the
        information and feedback we receive from you)
        <br />
        <br />
        • To improve customer service
        <br />
        (your information helps us to more effectively respond to your customer
        service requests and support needs)
        <br />
        <br />
        • To process transactions
        <br />
        <blockquote>
          Your information, whether public or private, will not be sold,
          exchanged, transferred, or given to any other company for any reason
          whatsoever, without your consent, other than for the express purpose
          of delivering the purchased product or service requested.
        </blockquote>
        <br />
        • To administer a contest, promotion, survey or other site feature
        <br />
        <br />
        <br />
        • To send periodic emails
        <br />
        <blockquote>
          The email address you provide for order processing, may be used to
          send you information and updates pertaining to your order, in addition
          to receiving occasional company news, updates, related product or
          service information, etc.
        </blockquote>
        Note: If at any time you would like to unsubscribe from receiving future
        emails, we include detailed unsubscribe instructions at the bottom of
        each email.
        <br />
        <br />
        <br />
        <br />
        <strong>How do we protect your information?</strong>
        <br />
        <br />
        We implement a variety of security measures to maintain the safety of
        your personal information when you place an order or access your
        personal information.
        <br />
        <br />
        We offer the use of a secure server. All supplied sensitive/credit
        information is transmitted via Secure Socket Layer (SSL) technology and
        then encrypted into our Payment gateway providers database only to be
        accessible by those authorized with special access rights to such
        systems, and are required toï¿½keep the information confidential.
        <br />
        <br />
        After a transaction, your private information (credit cards, social
        security numbers, financials, etc.) will not be stored on our servers.
        <br />
        <br />
        <strong>Do we use cookies?</strong>
        <br />
        <br />
        Yes (Cookies are small files that a site or its service provider
        transfers to your computers hard drive through your Web browser (if you
        allow) that enables the sites or service providers systems to recognize
        your browser and capture and remember certain information
        <br />
        <br />
        We use cookies to understand and save your preferences for future visits
        and compile aggregate data about site traffic and site interaction so
        that we can offer better site experiences and tools in the future.
        <br />
        <br />
        If you prefer, you can choose to have your computer warn you each time a
        cookie is being sent, or you can choose to turn off all cookies via your
        browser settings. Like most websites, if you turn your cookies off, some
        of our services may not function properly. However, you can still place
        orders by contacting customer service.
        <br />
        <br />
        <strong>Do we disclose any information to outside parties?</strong>
        <br />
        <br />
        We do not sell, trade, or otherwise transfer to outside parties your
        personally identifiable information. This does not include trusted third
        parties who assist us in operating our website, conducting our business,
        or servicing you, so long as those parties agree to keep this
        information confidential. We may also release your information when we
        believe release is appropriate to comply with the law, enforce our site
        policies, or protect ours or others rights, property, or safety.
        However, non-personally identifiable visitor information may be provided
        to other parties for marketing, advertising, or other uses.
        <br />
        <br />
        <strong>Third party links</strong>
        <br />
        <br />
        Occasionally, at our discretion, we may include or offer third party
        products or services on our website. These third party sites have
        separate and independent privacy policies. We therefore have no
        responsibility or liability for the content and activities of these
        linked sites. Nonetheless, we seek to protect the integrity of our site
        and welcome any feedback about these sites.
        <br />
        <br />
        <strong>California Online Privacy Protection Act Compliance</strong>
        <br />
        <br />
        Because we value your privacy we have taken the necessary precautions to
        be in compliance with the California Online Privacy Protection Act. We
        therefore will not distribute your personal information to outside
        parties without your consent.
        <br />
        <br />
        As part of the California Online Privacy Protection Act, all users of
        our site may make any changes to their information at anytime by logging
        into their account and changing their preferences.
        <br />
        <br />
        <strong>Childrens Online Privacy Protection Act Compliance</strong>
        <br />
        <br />
        We are in compliance with the requirements of COPPA (Childrens Online
        Privacy Protection Act), we do not collect any information from anyone
        under 13 years of age. Our website, products and services are all
        directed to people who are at least 13 years old or older.
        <br />
        <br />
        <strong>Terms and Conditions</strong>
        <br />
        <br />
        Please also visit our Terms and Conditions section establishing the use,
        disclaimers, and limitations of liability governing the use of our
        website at <a href='http://eduBakery.com'>http://eduBakery.com</a>
        <br />
        <br />
        <strong>Your Consent</strong>
        <br />
        <br />
        By using our site, you consent to our{' '}
        <a href='http://www.freeprivacypolicy.com/' target='_blank'>
          web site privacy policy
        </a>
        .<br />
        <br />
        <strong>Changes to our Privacy Policy</strong>
        <br />
        <br />
        If we decide to change our privacy policy, we will update the Privacy
        Policy modification date below.
        <br />
        <br />
        This policy was last modified on 1/9/2009
        <br />
        <br />
        <strong>Contacting Us</strong>
        <br />
        <br />
        If there are any questions regarding this privacy policy you may contact
        us using the information below.
        <br />
        <br />
        Steve Bushman
        <br />
        http://eduBakery.com
        <br />
        http://www.SuperCrosswordCreator.com
        <br />
        Steve@eduBakery.com
        <br />
        Steve@SuperCrosswordCreator.com
        <br />
      </div>
    </div>
  )
}
